<template>
  <div>
    <router-view />
  </div>
</template>
<script setup>
import { getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();
const store = useStore();
// cookie有token 獲取自己
if (proxy.$cookies.get("token")) {
  store.dispatch("getSelf");
} else {
  router.push("/login");
}
</script>

<style lang="scss">
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.el-popper {
  max-width: 800px !important;
}
body {
  overflow-x: hidden !important;
}
</style>
