import axios from "axios";
import store from "../store";
import VueCookies from "vue-cookies";
import router from "@/router";
import { ElMessage } from "element-plus";

const service = axios.create({
  baseURL: `${process.env.VUE_APP_DEFAULT_SRC}/${process.env.VUE_APP_DEFAULT_NGINX}`,
});

if (window.location.search.split("?id=")[1]) {
  service.defaults.headers.common["id"] =
    window.location.search.split("?id=")[1];
}
// X-LOCALIZATION 語系header 預設不送 = 英文 簡體中文 = zh-CN;
service.defaults.headers.common["X-LOCALIZATION"] = "zh-CN";
service.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";
service.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
service.defaults.headers.common.Authorization =
  "Bearer " + VueCookies.get("token");

let request = [];

service.interceptors.request.use(
  (config) => {
    if (request.indexOf(config.url.split("?")[0]) === -1) {
      store.commit("setLoad", true);
      request.push(config.url.split("?")[0]);
      return config;
    } else return false;
  },
  (error) => {
    ElMessage.error(error);
    return false;
  }
);

service.interceptors.response.use(
  (res) => {
    request.splice(request.indexOf(res.config.url.split("?")[0]), 1);
    if (request.length === 0) store.commit("setLoad", false);
    if (res.data.code === 10001) {
      ElMessage.error("Please login first");
      router.push("/login");
      VueCookies.remove("token");
      service.defaults.headers.common.Authorization = "";
      return res;
    }
    if (res.data.code !== 0) {
      ElMessage.error(`${res.data.message}  code: ${res.data.code}`);
      return res;
    }
    return res;
  },
  (error) => {
    if (error.config) {
      ElMessage.error(error);
      request.splice(request.indexOf(error.config.url.split("?")[0]), 1);
    }
    if (request.length === 0) store.commit("setLoad", false);
    // return false;
  }
);

export default service;
