import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/sass/main.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import ElMessage from "element-plus";
import VueCookies from "vue-cookies";
import axios from "./tools/axios";
import $request from "./tools/request";
import $returnTime from "./tools/returnTime.";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import twLocale from "element-plus/lib/locale/lang/zh-tw";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale: twLocale })
  .component("font-awesome-icon", FontAwesomeIcon);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$request = $request;
app.config.globalProperties.$returnTime = $returnTime;
app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.$onSuccess = function () {
  ElMessage.success("success");
};

library.add(faBell, faPhone, faAngleDown);

// 千分為
app.config.globalProperties.$thousands = function (num) {
  if (num) {
    var str = num.toString();
    var reg =
      str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
    return str.replace(reg, "$1,");
  } else {
    return num;
  }
};

// 刪除小數點後面多餘0
app.config.globalProperties.$delZero = function (num) {
  if (num * 1 && num * 1 != 0) {
    return num * 1;
  } else {
    return num;
  }
};

const context = app._context;
context.components.ElDialog.props.closeOnClickModal.default = false;
app.mount("#app");
