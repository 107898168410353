import service from "./axios";
// import router from "../router/index";

function $request({ method, api, data }) {
  switch (method) {
    case "post":
      return new Promise((resolve) => {
        service.post(api, data).then((res) => {
          let code;
          if (res) {
            code = String(res.data.code);
          }
          switch (code) {
            case "0":
              resolve(res.data);
              break;
            default:
              if (res) {
                resolve(res.data);
              }
              break;
          }
        });
      });
    case "get":
      return new Promise((resolve) => {
        service.get(api, { params: data }).then((res) => {
          let code;
          if (res) {
            code = String(res.data.code);
          }
          switch (code) {
            case "0":
              resolve(res.data);
              break;
            default:
              resolve(res.data);
              break;
          }
        });
      });
    case "put":
      return new Promise((resolve) => {
        service.put(api, data).then((res) => {
          let code;
          if (res) {
            code = String(res.data.code);
          }
          switch (code) {
            case "0":
              resolve(res.data);
              break;
            default:
              resolve(res.data);
              break;
          }
        });
      });
    case "patch":
      return new Promise((resolve) => {
        service.patch(api, data).then((res) => {
          let code;
          if (res) {
            code = String(res.data.code);
          }
          switch (code) {
            case "0":
              resolve(res.data);
              break;
            default:
              resolve(res.data);
              break;
          }
        });
      });
    case "delete":
      return new Promise((resolve) => {
        service.delete(api, data).then((res) => {
          let code;
          if (res) {
            code = String(res.data.code);
          }
          switch (code) {
            case "0":
              resolve(res.data);
              break;
            default:
              resolve(res.data);
              break;
          }
        });
      });
  }
}

export default $request;
