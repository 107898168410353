import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { ElMessage } from "element-plus";

const routes = [
  // {
  //   path: "/:catchAll(.*)",
  //   redirect: "/login",
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/",
    name: "wrap",
    component: () => import("../components/WrapLayout/ContentWrap.vue"),
    children: [
      {
        path: "/selfData",
        name: "selfData",
        meta: { fullName: "個人資訊" },
        component: () => import("../views/SelfDataView.vue"),
      },
      {
        path: "/sms.mobileArea",
        name: "sms.mobileArea",
        meta: { fullName: "國碼列表" },
        component: () => import("../views/AreaCodeView.vue"),
      },
      {
        path: "/sms.userSms",
        name: "sms.userSms",
        meta: { fullName: "短信提交" },
        component: () => import("../views/UserSmsView.vue"),
      },
      {
        path: "/firmConfig",
        name: "firmConfig",
        meta: { fullName: "通道列表" },
        component: () => import("../views/FirmConfigView.vue"),
      },
      {
        path: "/roles",
        name: "roles",
        meta: { fullName: "角色列表" },
        component: () => import("../views/RolesView.vue"),
      },
      {
        path: "/channel.manager",
        name: "channel.manager",
        meta: { fullName: "通道管理" },
        component: () => import("../views/ChannelManagerView.vue"),
      },
      {
        path: "/firm.manager",
        name: "firm.manager",
        meta: { fullName: "廠商管理" },
        component: () => import("../views/FirmManagerView.vue"),
      },
      {
        path: "/settings.adminUsers",
        name: "settings.adminUsers",
        meta: { fullName: "使用者列表" },
        component: () => import("../views/UserListView.vue"),
      },
      {
        path: "/settings.adminRoles",
        name: "settings.adminRoles",
        meta: { fullName: "角色管理" },
        component: () => import("../views/RoleListView.vue"),
      },
      {
        path: "/settings.adminPermissions",
        name: "settings.adminPermissions",
        meta: { fullName: "系統數據" },
        component: () => import("../views/AdminPermissions.vue"),
      },
      {
        path: "/sms.feerate",
        name: "sms.feerate",
        meta: { fullName: "地區費率" },
        component: () => import("../views/SmsFeerate.vue"),
      },
      {
        path: "/sms.report",
        name: "sms.report",
        meta: { fullName: "短信紀錄" },
        component: () => import("../views/SmsReport.vue"),
      },
      {
        path: "/balance.cashflow",
        name: "balance.cashflow",
        meta: { fullName: "金流紀錄" },
        component: () => import("../views/CashflowView.vue"),
      },
      {
        path: "/managerReport.smsDailyReport",
        name: "managerReport.smsDailyReport",
        meta: { fullName: "短信日報表" },
        component: () => import("../views/SmsDailyReport.vue"),
      },
      {
        path: "/sms.smsDailyReport",
        name: "sms.smsDailyReport",
        meta: { fullName: "短信日報表" },
        component: () => import("../views/UserSmsDailyReport.vue"),
      },
      {
        path: "/managerReport.smsRecordSearch",
        name: "managerReport.smsRecordSearch",
        meta: { fullName: "短信紀錄查詢" },
        component: () => import("../views/SmsRecordSearch.vue"),
      },
      {
        path: "/managerReport.batchSmsRecord",
        name: "managerReport.batchSmsRecord",
        meta: { fullName: "批量紀錄查詢" },
        component: () => import("../views/BatchSmsRecord.vue"),
      },
      {
        path: "/managerReport.firmDailyReport",
        name: "managerReport.firmDailyReport",
        meta: { fullName: "綜合日報表" },
        component: () => import("../views/FirmDailyReport.vue"),
      },
      {
        path: "/index.statistics",
        name: "index.statistics",
        meta: { fullName: "統計看板" },
        component: () => import("../views/StatisticsView.vue"),
      },
      {
        path: "/balance.userCashflow",
        name: "balance.userCashflow",
        meta: { fullName: "個人金流紀錄" },
        component: () => import("../views/UserCashflowView.vue"),
      },
      {
        path: "/sms.filterFile",
        name: "sms.filterFile",
        meta: { fullName: "過濾號碼" },
        component: () => import("../views/smsFilterFile.vue"),
      },
      // {
      //   path: "/settings.personal",
      //   name: "settings.personal",
      //   meta: { fullName: "基本資料" },
      //   component: () => import("../views/PersonalView.vue"),
      // },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  if (to.meta.login) {
    if (!store.state.isLogin) {
      ElMessage.error("Please login first");
      return {
        path: "/login",
      };
    }
  }
});

export default router;
