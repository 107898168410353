import { createStore } from "vuex";
import request from "../tools/request";
import { ElLoading } from "element-plus";
import router from "../router";
export default createStore({
  state: {
    isLoad: false,
    isLogin: false,
    selfData: {},
    permissions: [],
    statusList: [],
    typeList: [],
    menuList: [],
    isCredit: ["非信用帳戶", "信用帳戶"],
  },
  getters: {},
  mutations: {
    setLoad(state, boolean) {
      state.isLoad = boolean;
      const loading = ElLoading.service({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (!boolean) {
        loading.close();
      }
    },
    setLogin(state, boolean) {
      state.isLogin = boolean;
    },
    setSelf(state, data) {
      state.selfData = data;
    },
    setPermission(state, data) {
      state.permissions = data;
    },
    setStatus(state, data) {
      state.statusList = data;
    },
    setTypes(state, data) {
      state.typeList = data;
    },
    setMenu(state, data) {
      state.menuList = data;
    },
  },
  actions: {
    loginStatus(context = context, status = false) {
      context.commit("setLogin", status);
    },
    getMenu(context) {
      request({
        method: "get",
        api: `/users/menu`,
      }).then((res) => {
        if (res.code == 0) {
          context.commit("setMenu", res.data);
        }
      });
    },
    getPermission(context) {
      request({
        method: "get",
        api: `/users/permissions`,
      }).then((res) => {
        if (res.code == 0) {
          context.commit("setPermission", res.data);
        }
      });
    },
    getStatus(context) {
      request({
        method: "get",
        api: `/status`,
      }).then((res) => {
        if (res.code == 0) {
          context.commit("setStatus", res.data);
        }
      });
    },
    getTypes(context) {
      request({
        method: "get",
        api: `/types`,
      }).then((res) => {
        if (res.code == 0) {
          context.commit("setTypes", res.data);
        }
      });
    },
    getSelf(context) {
      request({
        method: "get",
        api: `/users/self`,
      }).then((res) => {
        if (res.code == 0) {
          if (router.currentRoute.value.name == "login") {
            router.push("/selfData");
          }
          context.commit("setSelf", res.data);
          this.dispatch("getMenu");
          this.dispatch("getPermission");
          // this.dispatch("getStatus");
          // this.dispatch("getTypes");
        }
      });
    },
  },
  modules: {},
});
